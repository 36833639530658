import { formatNumber, isNotNullish, Nullable } from '@tager/web-core';

import { PreviewSvgIconItems } from '@/components/DetailedCarPreviewSpecification/DetailedCarPreviewSpecification.types';
import {
  DetailedCarData,
  SimilarCarsDataParams,
} from '@/services/stock/typings';
import { SEOTemplate } from '@/typings/model';
import { colors, getTheme } from '@/constants/theme';
import {
  CarsStockBattery,
  CarsStockBody,
  CarsStockFuel,
  CarsStockGearbox,
  CarsStockStatusStock,
  CarsStockWheel,
} from '@/services/stock/enums';
import { PartnersCarsTestDriveParams } from '@/services/leads/typings';
import { submitPartnersCarsTestDrive } from '@/services/leads/leads-service';
import { googleEvent, GoogleEventParamType } from '@/utils/events';
import { CarTagsProps } from '@/components/CarTags/CarTags.types';
import { CarViewBody } from '@/components/CarView/types';
import { OptionType } from '@/typings/common';

export function svgIconItem(
  item: CarsStockBody | CarsStockFuel | CarsStockGearbox | CarsStockWheel
) {
  switch (item) {
    case CarsStockBody.Coupe:
      return PreviewSvgIconItems.COUPE;
    case CarsStockBody.Van:
      return PreviewSvgIconItems.VAN;
    case CarsStockBody.Hatchback:
      return PreviewSvgIconItems.HATCHBACK;
    case CarsStockBody.Cabriolet:
      return PreviewSvgIconItems.CABRIOLET;
    case CarsStockBody.Crossover:
      return PreviewSvgIconItems.CROSSOVER;
    case CarsStockBody.LiftBack:
      return PreviewSvgIconItems.LIFT_BACK;
    case CarsStockBody.Minibus:
      return PreviewSvgIconItems.MINIBUS;
    case CarsStockBody.Bus:
      return PreviewSvgIconItems.BUS;
    case CarsStockBody.Minivan:
      return PreviewSvgIconItems.MINIVAN;
    case CarsStockBody.Sedan:
      return PreviewSvgIconItems.SEDAN;
    case CarsStockBody.Chassis:
      return PreviewSvgIconItems.CHASSIS;
    case CarsStockBody.StationWagon:
      return PreviewSvgIconItems.STATION_WAGON;
    case CarsStockBody.Suv:
      return PreviewSvgIconItems.SUV;
    case CarsStockBody.Pickup:
      return PreviewSvgIconItems.PICKUP;
    case CarsStockBody.ShootingBrake:
      return PreviewSvgIconItems.SHOOTING_BRAKE;
    case CarsStockFuel.Electric:
      return PreviewSvgIconItems.ELECTRIC;
    case CarsStockGearbox.Manual:
      return PreviewSvgIconItems.MANUAL;
    case CarsStockGearbox.Robot:
      return PreviewSvgIconItems.ROBOT;
    default:
      return PreviewSvgIconItems.DEFAULT;
  }
}

export function switchNamesItems(
  item:
    | CarsStockFuel
    | CarsStockGearbox
    | CarsStockWheel
    | CarsStockBody
    | CarsStockBattery
    | CarsStockStatusStock
) {
  switch (item) {
    case CarsStockFuel.Petrol:
      return 'Бензин';
    case CarsStockFuel.Diesel:
      return 'Дизель';
    case CarsStockFuel.Gas:
      return 'Газ';
    case CarsStockFuel.Electric:
      return 'Электро ☘️';
    case CarsStockFuel.Hybrid:
      return 'Гибрид';
    case CarsStockGearbox.Manual:
      return 'Механическая';
    case CarsStockGearbox.Automatic:
      return 'Автоматическая';
    case CarsStockGearbox.Robot:
      return 'Робот';
    case CarsStockGearbox.Variator:
      return 'Вариатор';
    case CarsStockGearbox.DSG:
      return 'DSG';
    case CarsStockGearbox.Reductor:
      return 'Редуктор';
    case CarsStockWheel.Front:
      return 'Передний';
    case CarsStockWheel.Back:
      return 'Задний';
    case CarsStockWheel.Full:
      return 'Полный';
    case CarsStockBody.Sedan:
      return 'Седан';
    case CarsStockBody.LiftBack:
      return 'Лифтбек';
    case CarsStockBody.Crossover:
      return 'Кроссовер';
    case CarsStockBody.Minibus:
      return 'Микроавтобус';
    case CarsStockBody.Van:
      return 'Фургон';
    case CarsStockBody.Suv:
      return 'Внедорожник';
    case CarsStockBody.Hatchback:
      return 'Хэтчбек';
    case CarsStockBody.Coupe:
      return 'Купе';
    case CarsStockBody.Pickup:
      return 'Пикап';
    case CarsStockBody.StationWagon:
      return 'Универсал';
    case CarsStockBody.Minivan:
      return 'Минивэн';
    case CarsStockBody.Bus:
      return 'Автобус';
    case CarsStockBody.Chassis:
      return 'Шасси';
    case CarsStockBody.Cabriolet:
      return 'Кабриолет';
    case CarsStockBody.ShootingBrake:
      return 'Шутинг-брейк';
    case CarsStockBattery.LithiumIon:
      return 'Литий-ионная';
    case CarsStockBattery.LithiumIron:
      return 'Литий-железо-фосфатная';
    case CarsStockBattery.LithiumNickel:
      return 'Литий-никель-марганец-кобальт-оксидная';
    case CarsStockBattery.LithiumPolymeric:
      return 'Литий-полимерная';
    case CarsStockBattery.MetalAir:
      return 'Метало-воздушная';
    case CarsStockStatusStock.InStock:
      return 'В наличии';
    case CarsStockStatusStock.InProduction:
      return 'В производстве';
    case CarsStockStatusStock.Preorder:
      return 'Под заказ';
    case CarsStockStatusStock.OnWay:
      return 'В пути';
    default:
      return '';
  }
}

export const convertDetailedCarSEOTemplate = (
  car: Nullable<DetailedCarData>,
  seoTemplate: Nullable<SEOTemplate>
): Nullable<SEOTemplate> => {
  if (!car || !seoTemplate) {
    return null;
  }

  const { title, description } = seoTemplate;

  const replaceText = (text: Nullable<string>): Nullable<string> => {
    const brand = car.catalog.brand.name;
    const model = car.catalog.model?.name ?? '';
    const body = car.params.body?.label ?? '';
    const year = car.params.year ? String(car.params.year) : '';
    const volume = car.params.engineCapacity
      ? car.params.engineCapacity.toFixed(1)
      : '';
    const color =
      car.params.color && car.params.color.label ? car.params.color.label : '';
    const vin = car.vin ? car.vin : String(car.id);
    const fuel = car.params.fuel?.label ?? '';
    const gearbox = car.params.gearbox?.label ?? '';

    return (
      text
        ?.replaceAll('{{brand}}', brand)
        ?.replaceAll('{{brand|lowercase}}', brand.toLowerCase())
        ?.replaceAll('{{model}}', model)
        ?.replaceAll('{{model|lowercase}}', model.toLowerCase())
        ?.replaceAll('{{body}}', body)
        ?.replaceAll('{{body|lowercase}}', body.toLowerCase())
        ?.replaceAll('{{year}}', year)
        ?.replaceAll('{{volume}}', volume)
        ?.replaceAll('{{color}}', color)
        ?.replaceAll('{{color|lowercase}}', color.toLowerCase())
        ?.replaceAll('{{vin}}', vin)
        ?.replaceAll('{{fuel}}', fuel)
        ?.replaceAll('{{fuel|lowercase}}', fuel.toLowerCase())
        ?.replaceAll('{{gearbox}}', gearbox)
        ?.replaceAll('{{gearbox|lowercase}}', gearbox.toLowerCase())
        ?.split(' ')
        ?.filter((item) => item)
        ?.join(' ') ?? text
    );
  };

  return {
    ...seoTemplate,
    title: replaceText(title),
    description: replaceText(description),
    openGraphImage: car.media.images.length ? car.media.images[0] : '',
  };
};

export const getSimilarCarsDataParams = (
  car: DetailedCarData
): SimilarCarsDataParams | undefined => {
  const theme = getTheme();

  return theme !== 'main'
    ? { brands: String(car.catalog.brand.id) }
    : undefined;
};

export async function handleSubmitPartnersCarsTestDriveForm(
  values: PartnersCarsTestDriveParams,
  event?: { type?: string; params?: GoogleEventParamType }
): Promise<void> {
  try {
    await submitPartnersCarsTestDrive(values).then(() => {
      if (event?.type) {
        googleEvent(event.type, event.params);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export const getDetailedCarTags = (car: DetailedCarData): CarTagsProps => {
  return {
    tags: [
      car.params.engineCapacity
        ? { title: car.params.engineCapacity.toFixed(1) }
        : undefined,
      car.params.fuel
        ? {
            title: car.params.fuel.label,
            background:
              car.params.fuel.id === CarsStockFuel.Electric
                ? colors.green200
                : undefined,
          }
        : undefined,
      car.params.year ? { title: String(car.params.year) } : undefined,
      car.params.gearbox ? { title: car.params.gearbox.label } : undefined,
      car.params.body ? { title: car.params.body.label } : undefined,
      car.params.mileage
        ? {
            title: formatNumber(car.params.mileage) + ' км',
            infoText: 'Пробег',
          }
        : undefined,
      car.params.battery ? { title: car.params.battery.label } : undefined,
      car.params.batteryCapacity
        ? {
            title: car.params.batteryCapacity + ' кВт*ч',
            infoText: 'Емкость батареи',
          }
        : undefined,
      car.params.distanceLimit
        ? {
            title: formatNumber(car.params.distanceLimit) + ' км',
            infoText: 'Запас хода',
          }
        : undefined,
    ].filter(isNotNullish),
    VIN: car.vin ?? '',
  };
};

export function getEventParams(car: DetailedCarData): GoogleEventParamType {
  return {
    car: `${car.catalog.brand.name} ${car.catalog.model?.name ?? ''}`,
    stockStatus: car.stockStatus?.label ?? '',
  };
}

export function getCarViewBody(body: CarsStockBody): CarViewBody {
  switch (body) {
    case CarsStockBody.Sedan:
      return 'sedan';
    case CarsStockBody.LiftBack:
      return 'lift_back';
    case CarsStockBody.Crossover:
      return 'crossover';
    case CarsStockBody.Minibus:
      return 'minibus';
    case CarsStockBody.Van:
      return 'van';
    case CarsStockBody.Suv:
      return 'suv';
    case CarsStockBody.Hatchback:
      return 'hatchback';
    case CarsStockBody.Coupe:
      return 'coupe';
    case CarsStockBody.Pickup:
      return 'pickup';
    case CarsStockBody.StationWagon:
      return 'station_wagon';
    case CarsStockBody.Minivan:
      return 'minivan';
    case CarsStockBody.Bus:
      return 'bus';
    case CarsStockBody.Chassis:
      return 'chassis';
    case CarsStockBody.Cabriolet:
      return 'cabriolet';
    case CarsStockBody.ShootingBrake:
      return 'shooting_brake';
    default:
      return 'crossover';
  }
}

export const getDealersOptions = (car: DetailedCarData): OptionType[] => {
  if (!car.dealers || car.dealers.length === 0) {
    return [];
  }

  const { dealers } = car;

  return dealers.flatMap(({ locations }) =>
    locations.map(({ name, address, crmId }) => ({
      label: `${name}, ${address}`,
      value: String(crmId),
    }))
  );
};
